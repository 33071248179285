/* globalStyles */

:root {
  --aspect-ratio: 0.5625;
}

.ant-table-selection-col,
.ant-table-selection-column {
  visibility: hidden;
}

.ant-modal-mask {
  backdrop-filter: blur(10px);
  background-color: #000000cc;
}

.ant-input-clear-icon-hidden {
  visibility: visible;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  /* height: 5vh !important; */
  padding: 2%;
  background: #e2e8ec !important;
  font-size: 18px;
  font-weight: 600;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  background: #e2e8ec !important;
  border-radius: 5px 0 0 5px !important;
}

.ant-input-group-addon {
  width: 55px !important;
  border: 1px solid #cfd9e0 !important;
  border-radius: 5px !important;
  font-size: 1.5vw !important;
}

/* .ant-input-group-addon:first-child {
  background-color: #BAC2C6;
  color: #fff;
}

.ant-input-group-addon:last-child {
  background-color: #76B769;
  color: #fff;
} */

.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 1.5vw;
  padding-top: 5px;
  color: #000000;
}

/* Selling Products Table */

.ant-table-thead > tr > th {
  font-size: 1vw;
  font-weight: 600;
  color: #000000;
  background: #e0e7ef;
}

.ant-table-tbody > tr > td {
  font-size: 0.9vw;
  font-weight: normal;
  color: #000000;
}

.ant-table-tbody > tr:hover {
  background: none;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #ebeded;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #ebeded;
}

.ant-table-tbody > tr > td {
  border: none;
}

/* Ends */

/* Product Category List Menu */

.ant-menu-item,
.ant-menu-item-only-child {
  color: #607d8b;
  /* font-size: 1.1vw; */
  padding-left: 25px !important;
}

.ant-menu-item-only-child:hover {
  color: #607d8b;
}

.ant-menu-item-selected {
  color: #374957;
  font-weight: 600;
}

.ant-menu-item-selected:hover {
  color: #374957;
  font-weight: 600;
}

.ant-menu-submenu-title,
.ant-menu-submenu-title:hover {
  color: #000000;
  font-size: 1.2vw;
}

.ant-menu-submenu-arrow,
.ant-menu-submenu-arrow:hover {
  color: #607d8b !important;
  font-weight: 600;
  font-size: 1.1vw;
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}

.ant-menu-sub {
  max-height: calc(var(--aspect-ratio) * 68vw);
  min-height: calc(var(--aspect-ratio) * 68vw);
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-menu-sub::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.ant-menu-submenu-arrow,
.ant-menu-submenu-arrow:hover {
  color: #607d8b !important;
  font-weight: 600;
  font-size: 1.3vw;
  top: 53%;
}

/* Ends */

/* Order History Tabs */

.ant-input-prefix {
  margin-right: 20px;
}

.ant-tabs-tab {
  margin: 0 0 0 90px;
  padding: 12px 0 5px 0;
  font-size: 1vw;
  font-weight: 600;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0px 0 90px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: #000000;
  font-weight: 600;
}

/* .ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: #0c4da9;
} */

.ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  /* width: 60px !important; */
  height: 3px !important;
  /* margin-left: -12px !important; */
  border-radius: 10px;
  background: #0c4da9;
}

/* Ends */

/* Returns Modal */

.ant-modal-title {
  margin: 0;
  color: #0c4da9;
  font-weight: 600;
  font-size: 1.1vw;
}

.ant-modal-close-x {
  font-size: 1.3vw;
  color: #eb3a4c;
}

/* Ends */

/* Attributes Select Box */

/* .ant-select-item {
  padding: 16px;
  font-size: 1.1vw !important;
  font-weight: 500 !important;
} */

.ant-select-selector:hover,
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-selector {
  /* height: 7vh !important; */
  border-radius: 5px !important;
}

/* .ant-select-selection-item {
  font-size: 1.3vw !important;
  color: #000000 !important;
  margin-top: 0.5vw;
}

.ant-select-arrow {
  top: 25px;
  right: 15px;
  font-size: 1.3vw;
  color: #000000;
} */

.ant-radio {
  vertical-align: middle;
}

/* Ends */

/* .ant-modal {
  top: 75px;
} */

.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]) {
  justify-content: flex-start;
}

.ant-card-head {
  padding: 0 10px;
}

.ant-card-head-title {
  padding: 12px 0px !important;
}

/* .ant-ribbon {
  background-color: #02C437;
} */

.ant-ribbon.ant-ribbon-placement-start {
  left: 0px;
}

.ant-ribbon-corner {
  border: none;
}

.ant-ribbon {
  content: "";
  font-size: 10px !important;
  line-height: 4px !important;
  border-left: 0px solid transparent;
  border-right: 5px solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #000;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-checked .ant-radio-inner:hover {
  border-color: #000000 !important;
}

.ant-radio-inner::after {
  background-color: #000000;
}

.ant-radio-checked::after {
  border: 1px solid #000000;
}

.ant-table-small .ant-table-thead > tr > th {
  background-color: #e0e7ef;
}

@media only screen and (max-width: 1024px) {
  /* .ant-menu-sub {
    max-height: 43vw;
    min-height: 43vw;
    overflow-x: hidden;
    overflow-y: auto;
  } */

  .ant-tabs-tab {
    margin: 0 0 0 100px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 100px;
  }

  .ant-select-selection-item {
    margin-top: 0.6vw;
  }

  .ant-menu-sub {
    max-height: 80vh;
    min-height: 80vh;
  }

  .ant-table-thead > tr > th {
    font-size: 13px;
  }
  
  .ant-table-tbody > tr > td {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1920px) {
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    padding: 17px;
    background: #e2e8ec !important;
    font-size: 18px;
    font-weight: 600;
  }

  .ant-menu-sub {
    max-height: calc(var(--aspect-ratio) * 72vw);
    min-height: calc(var(--aspect-ratio) * 72vw);
  }

  .ant-input-group-addon {
    width: 80px !important;
  }

  .ant-select-arrow {
    top: 29px;
    right: 20px;
  }

  .ant-select-selection-item {
    margin-top: 0.8vw;
    font-size: 1.1vw !important;
  }
}

.ant-badge-status-text {
  font-size: 12px;
}

/* Menu Drawer Styles */

.ant-drawer-header {
  background: #0c4da9;
  border-bottom: 1px solid #0c4da9;
}

.ant-drawer-title {
  color: #ffffff;
  font-weight: 600;
}

.ant-drawer-close,
.ant-drawer-close:hover {
  padding: 16px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}
